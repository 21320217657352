import React from "react";

export default function HeadingShrinkM() {
  return (
    <>
      <div className=" featuresHeading">
        <h2 className=" globalHeading">Stound’s Noteworthy Features</h2>
      </div>
    </>
  );
}
